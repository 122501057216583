import React from 'react';
import { Box, Container, Image, Heading, Text } from 'theme-ui';
import { keyframes } from '@emotion/core';
import logoImage from 'assets/dominus-logo.png';
import bannerIcon1 from 'assets/lightbulb.svg';
import bannerBg from 'assets/radovi12.webp';
import bannerIcon2 from 'assets/lightning.svg';
import bannerIcon4 from 'assets/cable.svg';
import bannerIcon5 from 'assets/plug.svg';
import bannerIcon7 from 'assets/dot-pattern.svg';

const Banner = () => {
  return (
    <Box as="section" id="banner" sx={styles.banner}>
      <Container sx={styles.container}>
        <Image
          sx={styles.bannerIcon1}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon1}
        />
        <Image
          sx={styles.bannerIcon2}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon2}
        />

        <Image
          sx={styles.bannerIcon4}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon4}
        />
        <Image
          sx={styles.bannerIcon5}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon5}
        />


        <Image
          sx={styles.bannerIcon7}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon7}
        />

        <Image width="120px" sx={styles.logo} src={logoImage} alt="logo image" />
        <Box sx={styles.bannerImageContainer}>
          <Heading as="h1">Dobrodošli u <b className={styles.boldTitle}>Dominus</b></Heading>
          {/* <Image src={bannerImage} sx={styles.bannerImage} alt="banner image" /> */}
          <Text as="p">Vaš pouzdan partner za sve električarske usluge.</Text>
          <Box sx={styles.overlay}></Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;

const bannerAnim1 = keyframes`
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }

    30% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    60% {
        transform: rotate3d(1, 0, 0, 0deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    100% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
`;

const bannerAnim2 = keyframes`
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: center center;
    }

    50% {
        transform: translateY(50px) translateX(50px) rotate(45deg);
        transform-origin: right bottom;
    }

    80% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: left top;
    }

    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
`;

const styles = {
  banner: {
    overflow: 'hidden',
    backgroundColor: '#F9FBFD',
    textAlign: 'center',
    pt: ['110px', null, null, null, '130px'],
    h1: {
      fontSize: ['36px', null, null, '36px', '38px', '62px', '64px'],
      lineHeight: 1.25,
      color: '#fff',
      fontWeight: 700,
      width: '100%',
      maxWidth: ['100%', null, null, '55%', '500px', '640px', '851px'],
      mx: 'auto',
      mb: ['40px', null, null, '65px'],
    },
    p: {
      fontSize: "24px",
      color: "#fff",
    },
  },
  logo: {
    display: 'block',
    borderRadius: '50%',
    mx: 'auto',
    boxShadow: '0px 15px 35px rgba(65, 104, 139, 0.12)',
  },
  bannerImageContainer: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1000px',
    height: '482px',
    maxWidth: ['100%', null, null, '80%', null, '100%'],
    mx: 'auto',
    my: '80px',
    backgroundImage: `url(${bannerBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.4)',
    opacity: '.8',


    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: 1,
    },

    zIndex: 2,

    '> h1, > p': {
      position: 'relative',
      zIndex: 3,
      color: '#fff',
    },
  },

  boldTitle: {
    color: "#fff",
    textDecoration: 'underline',
  },
  bannerImage: {
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  container: {
    position: 'relative',
    '.bannerIcon': {
      position: 'absolute',
      display: ['block', null, null, null, 'block'],
    },
  },
  bannerIcon1: {
    top: '10%',
    left: '10%',
    animation: `${bannerAnim2} 8s linear infinite`,
    width: '40px',
  },
  bannerIcon2: {
    top: '10%',
    right: '10%',
    animation: `${bannerAnim2} 8s linear infinite`,
  },
  bannerIcon3: {
    bottom: '40px',
    right: '-120px',
    animation: `${bannerAnim1} 5s ease-out infinite`,

  },
  bannerIcon4: {
    bottom: '130px',
    left: '80px',
    animation: `${bannerAnim1} 5s ease-out infinite`,
    display: [null, null, null, null, 'block'],
  },
  bannerIcon5: {
    bottom: '200px',
    left: '80px',
    animation: `${bannerAnim1} 8s linear infinite`,
  },
  bannerIcon7: {
    bottom: '30%',
    right: '0%',
  },
};
