import React, { useState } from 'react';
import { Container, Box, Button } from 'theme-ui';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import BlockTitle from 'components/block-title';

import workImage1 from 'assets/radovi.webp';
import workImage2 from 'assets/radovi2.webp';
import workImage3 from 'assets/radovi3.webp';
import workImage4 from 'assets/radovi4.webp';
import workImage5 from 'assets/radovi5.webp';
import workImage6 from 'assets/radovi6.webp';
import workImage7 from 'assets/radovi7.webp';
import workImage8 from 'assets/radovi8.webp';
import workImage9 from 'assets/radovi9.webp';
import workImage10 from 'assets/radovi10.webp';
import workImage11 from 'assets/radovi11.webp';
import workImage12 from 'assets/radovi12.webp';
import workImage13 from 'assets/radovi13.webp';
import workImage14 from 'assets/radovi14.webp';
import workImage15 from 'assets/radovi15.webp';
import workImage16 from 'assets/radovi16.webp';
import workImage17 from 'assets/radovi17.webp';
import workImage18 from 'assets/radovi18.webp';

const OUR_WORK = [
    workImage1,
    workImage2,
    workImage3,
    workImage4,
    workImage5,
    workImage6,
    workImage7,
    workImage8,
    workImage9,
    workImage10,
    workImage11,
    workImage12,
    workImage13,
    workImage14,
    workImage15,
    workImage16,
    workImage17,
    workImage18,
];

const OurWork = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [visibleImages, setVisibleImages] = useState(9); // Inicijalni broj vidljivih slika

    const handleShowMore = () => {
        setVisibleImages(OUR_WORK.length); // Prikazuje sve slike
    };

    const handleShowLess = () => {
        setVisibleImages(9); // Vraća na inicijalnih 9 slika
    };

    return (
        <Box as="section" id="work" sx={styles.blogs}>
            <BlockTitle
                title="Naši radovi"
                text="Pogledajte neke od radova koje smo izveli:"
            />
            <Container>
                <Box sx={styles.grid}>
                    {OUR_WORK.slice(0, visibleImages).map((image, index) => (
                        <Box
                            key={index}
                            sx={styles.imageWrapper}
                            onClick={() => {
                                setPhotoIndex(index);
                                setIsOpen(true);
                            }}
                        >
                            <Box sx={styles.image}>
                                <img src={image} alt={`Rad ${index + 1}`} />
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box sx={styles.buttonWrapper}>
                    {visibleImages < OUR_WORK.length ? (
                        <Button sx={styles.headerBtn} variant="buttons.primary" onClick={handleShowMore}>
                            Prikaži još
                        </Button>
                    ) : (
                        <Button sx={styles.headerBtn} variant="buttons.primary" onClick={handleShowLess}>
                            Prikaži manje
                        </Button>
                    )}
                </Box>
                {isOpen && (
                    <Lightbox
                        mainSrc={OUR_WORK[photoIndex]}
                        nextSrc={OUR_WORK[(photoIndex + 1) % OUR_WORK.length]}
                        prevSrc={OUR_WORK[(photoIndex + OUR_WORK.length - 1) % OUR_WORK.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + OUR_WORK.length - 1) % OUR_WORK.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % OUR_WORK.length)
                        }
                    />
                )}
            </Container>
        </Box>
    );
};

export default OurWork;

const styles = {
    blogs: {
        pt: ['80px', null, null, null, '80px', null, '100px'],
        pb: ['40px', null, null, null, '140px', null, '100px'],
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
        gap: '20px',
    },
    imageWrapper: {
        cursor: 'pointer',
        height: '300px',
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        '&:hover': {
            transform: 'scale(1.05)',
            transition: 'transform 0.3s ease',
        },
    },
    image: {
        width: '400px',
        height: '100%',
        objectFit: 'contain',
        borderRadius: '8px',
        '& img': {
            width: '450px',
            objectFit: 'contain',
            borderRadius: '8px',
        },
    },
    buttonWrapper: {
        textAlign: 'center',
        mt: '20px',
    },
    headerBtn: {
        backgroundColor: '#FFC32C',
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '-0.16px',
        textDecoration: 'none',
        borderRadius: '5px',
        color: '#ffffff',
        padding: '6.5px 24px',
        display: 'inline-block',
        ml: ['0', null, null, 'auto', '0'],
        mr: ['0', null, null, '20px', '0'],
        '&:hover': {
            color: '#fff',
        },
    },
};
